import React from 'react';

import CookieConsent from "react-cookie-consent";
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

import '../assets/sass/main.scss';

export function CookieBanner() {
    const location = useLocation()

    return (      
        <CookieConsent
            location="bottom"
            buttonText="Allow"
            onAccept={() => {
                initializeAndTrack(location)
            }}
            declineButtonText="Block"
            enableDeclineButton
            flipButtons
            cookieName="gatsby-gdpr-google-analytics"
            style={{maxWidth: "700px", fontSize: "12px", lineHeight: "14px", margin: "auto", left: "0px", right: "0px"}}
            buttonStyle={{background: "None", color: "white", margin: "0", padding: "0 10px"}}
            declineButtonStyle={{background: "None", color: "white", margin: "0", padding: "0 10px", fontWeight: "300"}}
            contentStyle={{margin: "0 10px"}}
        >
            This site uses cookies to track visits - no personal information is stored.
        </CookieConsent>
    );
}

export default CookieBanner;
